/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import ReactPlayer from "react-player"
import Lightbox from "react-image-lightbox"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AboutUsPage = ({ pageContent }) => {
  const lang = "en"
  const [videoToPopup, setVideoToPopup] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpenPackaging, setIsOpenPackaging] = useState(false)
  const [galleryPackaging, setGalleryPackaging] = useState([])

  return (
    <Layout lang={lang}>
      <SEO
        title={
          pageContent.seo.title ? pageContent.seo.title : pageContent.title
        }
        description={pageContent.seo.metaDesc && pageContent.seo.metaDesc}
        image={
          pageContent.seo.opengraphImage &&
          pageContent.seo.opengraphImage.localFile.publicURL
        }
      />

      <section className="projects">
        {pageContent?.acfPresentation?.content?.map((item, index) => (
          <div className="container" key={index}>
            <div className="header-wrapper">
              <h3>{item?.category}</h3>
            </div>
            <div className="row">
              {item?.categoryProjects.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="projects__item">
                    {item?.videoGif ? (
                      <div
                        className="projects__item-thumbnail projects__item-thumbnail--video"
                        style={{
                          backgroundImage: `url('${item?.videoGifThumbnail?.localFile?.publicURL}')`,
                        }}
                      >
                        <button
                          className="projects__item-play"
                          onClick={() => setVideoToPopup(item?.vimeoLink)}
                        >
                          Play
                        </button>
                        <ReactPlayer
                          url={item?.videoGif?.localFile?.publicURL}
                          width="100%"
                          height="100%"
                          playing={true}
                          controls={false}
                          muted={true}
                          loop={true}
                          playsinline
                        />
                      </div>
                    ) : item?.gallery ? (
                      <button
                        onClick={e => {
                          e.preventDefault()
                          setIsOpenPackaging(true)
                          setPhotoIndex(0)
                          setGalleryPackaging(() =>
                            item.gallery.map(item => item.localFile.publicURL)
                          )
                        }}
                        className="projects__item-thumbnail projects__item-thumbnail--button"
                        style={{
                          backgroundImage: `url('${item?.image?.localFile?.publicURL}')`,
                        }}
                      >
                        Gallery
                      </button>
                    ) : (
                      <Link
                        to={`${item?.link}?page=presentation`}
                        className="projects__item-thumbnail"
                        style={{
                          backgroundImage: `url('${item?.image?.localFile?.publicURL}')`,
                        }}
                      ></Link>
                    )}
                    <h4>{item?.header}</h4>
                    {item?.subHeader && <p>{item?.subHeader}</p>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>

      {videoToPopup && (
        <>
          <div className="video-modal video-modal--bigger">
            <div className="video-container">
              <div className="video-container__video">
                <ReactPlayer
                  url={videoToPopup}
                  width="100%"
                  height="100%"
                  playing={videoToPopup}
                  controls={true}
                  muted={false}
                />
              </div>
            </div>
          </div>
          <div
            className="video-modal__overlay"
            onClick={() => setVideoToPopup(false)}
            role="presentation"
          />
        </>
      )}

      {isOpenPackaging && (
        <Lightbox
          mainSrc={galleryPackaging[photoIndex]}
          nextSrc={galleryPackaging[(photoIndex + 1) % galleryPackaging.length]}
          prevSrc={
            galleryPackaging[
              (photoIndex + galleryPackaging.length - 1) %
                galleryPackaging.length
            ]
          }
          onCloseRequest={() => setIsOpenPackaging(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + galleryPackaging.length - 1) %
                galleryPackaging.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % galleryPackaging.length)
          }
        />
      )}
    </Layout>
  )
}

// cG9zdDoxNTY3

export default () => (
  <StaticQuery
    query={graphql`
      {
        pageContent: wpPage(id: { eq: "cG9zdDoxNDI2" }) {
          seo {
            title
            metaDesc
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
          acfPresentation {
            content {
              category
              categoryProjects {
                header
                subHeader
                link
                image {
                  localFile {
                    publicURL
                  }
                }
                vimeoLink
                videoGif {
                  localFile {
                    publicURL
                  }
                }
                videoGifThumbnail {
                  localFile {
                    publicURL
                  }
                }
                gallery {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <AboutUsPage {...data} />}
  />
)
